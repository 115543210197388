<template>
  <div class="wrap">
    <Navbar :title="$t('newTab.draw')" :leftArrow="false" fixed />
    <div class="content">
      <div class="game">
        <div class="game_list" v-for="item, index in merchants" :key="index" @click="$router.push('/GameRecord?type=' + item.type)">
          <div class="list_left">
            <img class="img" :src="item.picture" alt="">
            <div class="left_cont">
              <div class="title">{{ item.title }}</div>
              <div class="number">
                <!-- <template v-if="item.category == 'k3'">
                  <div class="imgs">
                    <img v-for="val, num in item?.lastinfo?.codes" :key="num"
                      :src="require(`@/assets/icon/dice/${val}.png`)" alt="">
                  </div>
                </template> -->
                <template>
                  <div class="ball_wrap">
                    <div class="ball" v-for="(val, num) in item?.lastinfo?.codes" :key="num">
                      {{ val }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="list_right">
            <div class="trend">{{ $t('trend') }}</div>
            <van-icon name="arrow" color="var(--gray1)" size="17" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/JNav";
import { Icon } from "vant";
export default {
  components: {
    Navbar,
    [Icon.name]: Icon
  },
  data() {
    return {

    }
  },
  computed: {
    merchants() {
      const arr = this.$store.getters.AllGameList
      return arr || [];
    },
  },
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: calc(100vh - 50px);

  .content {
    padding: calc(11rem / 16);

    .game {
      display: flex;
      flex-direction: column;
      row-gap: calc(11rem / 16);

      &_list {
        background-color: var(--light);
        padding: calc(12rem / 16);
        border-radius: calc(4rem / 16);
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: calc(20rem / 16);

        .list_left {
          display: flex;

          .img {
            width: calc(52rem / 16);
            height: calc(52rem / 16);
            display: block;
          }

          .left_cont {
            margin-left: calc(12rem / 16);

            .title {
              font-size: calc(18rem / 16);
              color: var(--textColor);
              margin-top: calc(6rem / 16);
            }

            .number {
              margin-top: calc(8rem / 16);

              .imgs {
                display: flex;
                align-items: center;
                gap: calc(10rem / 16);

                img {
                  width: calc(28rem / 16);
                  height: calc(28rem / 16);
                  display: block;
                }
              }

              .ball_wrap {
                width: calc((28rem / 16) * 5 + 10px);

                .ball {
                  border-radius: 50%;
                  width: calc(28rem / 16);
                  height: calc(28rem / 16);
                  line-height: calc(28rem / 16);
                  margin: 1px;
                  text-align: center;
                  background: var(--theme);
                  color: #000;
                  display: inline-block;
                  background-image: url("../../assets/icon/game_ball.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  font-size: calc(15rem / 16);
                }
              }
            }
          }
        }

        .list_right {
          display: flex;
          align-items: center;
          .trend{
            font-size: calc(13rem / 16);
            color: var(--red);
            margin-right: calc(10rem / 16);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>